import React from "react";
import PageLayout from "../Layout/PageLayout";

const Privacy = () => {
  return (
    <PageLayout>
      <div className="col-md-12">
        <div className="head">
          <h2>MEDICO LEGAL/ PRIVACY POLICY</h2>
        </div>
      </div>
      <section className="privacy-second">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="terms-text">

                <p>Medyseva Technologies Private Limited (referred to as “Medyseva” “we” “our” “us”) is committed to protecting and respecting the privacy of its Patients, Empanelled Doctors/ Physicians, Visitors (jointly & separately referred to as “Individual”) by maintaining the confidentiality of their Personal Information. Medyseva’s Medico Legal/ Privacy Policy (hereinafter referred to as “Policy”) is in compliance with the Indian Information Technology Act, 2000 and rules (“IT Act”), Indian Medical Council Act, 1956 (“IMC Act”), Indian Medical Council (Professional conduct, Etiquette and Ethics) Regulations, 2002 (“IMC Code”) [collectively referred to as “Data Protection Laws”]. We encourage the Individual to review this Policy carefully as by using the Medyseva community, they provide consent to this Policy and the practices followed by us
                </p>

                <p>
                  This website is operated by Medyseva, which is in the business of electronic health care solutions (“E-health”) wherein it provides a platform to its users to electronically consult and procure preliminary medical advice from the best doctors of the country without the need of physically travelling to visit such doctors.                </p>
                <h4 className="small-head">1.	GENERAL</h4>
                <p>
                  This Policy applies to all the services, consultations, opinions, websites, portals and Apps (hereinafter referred to as “Services” in this policy) offered by Medyseva, except where otherwise noted                </p>
                <p>
                  Individual’s privacy is important to us, therefore, we take the protection of their Personal Information very seriously                </p>

                <h4 className="small-head">PURPOSE</h4>
                <p>
                  The purpose of this Policy is to provide consistent standards to ensure that the Individual’s Personal Information which is under the custody of Medyseva and to which Employees of Medyseva have access to while performing their role remains protected. All Individual’s Personal Information is treated with complete confidentiality and IS NOT shared with anyone who does not have the right to access                </p>

                {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                </p> */}

                <h4 className="small-head">DEFINITIONS</h4>
                <p>
                  Individual: It includes but is not limited to Patients, empanelled Doctors/ Physicians, Visitors etc                </p>
                <p>
                  Personal Information: It means any information about an Individual including but not limited to Individual’s:                </p>
                <p> a)	name, address, personal number, email ID;</p>
                <p>b)	race, national or ethnic origin, colour or religious beliefs or associations;</p>

                <p>c)	fingerprints, blood type or other inheritable characteristics;</p>

                <p>d)	healthcare history, including a physical or mental disability;</p>

                <p>e)	educational, financial, criminal or employment history; </p>

                <p>f)	all other Sensitive Information of the Patients.
                  Personal Information can be recorded in any format, including books, documents, letters, vouchers, papers, forms and any other thing on which Personal Information is recorded or stored by graphic, electronic, mechanical or other means.
                </p>

                <p>
                  Employees: It means all the directors, managers, officers, employees, medical staff, house staff, business associates, interns, students and agents engaged by Medyseva.
                </p>




                <h4 className="small-head">PROTECTION OF INDIVIDUAL’S PERSONAL INFORMATION</h4>



                <p>The following Rules or Principles shall apply:</p>
                <p> 1)	Individual’s Personal Information shall be processed fairly and lawfully;</p>
                <p>2)	Individual’s Personal Information shall be obtained only for specified and lawful purposes, and must not be processed in a manner which is incompatible with those purposes;</p>

                <p>3)	Individual’s Personal Information must be adequate, relevant and not excessive in relation to the purposes for which it is processed;</p>

                <p>4)	Individual must, as and when requested, review their Personal Information and ensure that their Personal Information is accurate and, where necessary, keep it up to date;</p>

                <p>5)	Individual’s Personal Information processed for any purpose shall not be kept for longer than is necessary or is otherwise required under any other law for the time being in force;</p>

                <p>6)	Appropriate measures shall be taken against unauthorised or unlawful processing of Personal Information and against accidental loss or destruction of, or damage to, or theft of, Personal Information; </p>
                <p>7)	Individual’s Personal Information shall not be transferred to a third party unless the transferee entity ensures the same level of protection of Personal Information that is adhered to by Medyseva. Personal Information shall be transferred only if it is necessary for the performance of a lawful contract between Medyseva and the transferee entity, subject to prior permission from the Individual.</p>





                <h4 className="small-head">COLLECTION OF PERSONAL INFORMATION</h4>



                <p>Individual’s Personal Information collected by Medyseva is limited to what is required in order to fulfil the required purposes. Before collecting any Personal Information from the Individual, Medyseva through its Employees provide guidance to all the Individual at all the registration and admission counters advising them of:</p>
                <p>1)	the purpose for which the said Personal Information is being collected and the authorization for doing so;</p>
                <p>2)	how the said Personal Information will be used by Medyseva and its Employees; </p>
                <p>3)	whom to contact if the Individual has any questions about the collection and use of Personal Information.</p>








                <h4 className="small-head">COLLECTION OF INDIVIDUAL’S PERSONAL INFORMATION FROM THIRD PARTIES</h4>



                <p>Medyseva collects Individual’s Personal Information directly from the Individual to whom it relates unless the circumstances are as such that such Personal Information cannot be collected from the said Individual. In circumstances where it is not possible to collect Personal Information directly from the Individual, Employees may collect such Personal Information indirectly from other sources as authorised by law. Such circumstances are where:</p><p>1)	the purpose for which the said Personal Information is being collected and the authorization for doing so;</p>
                <p>1)	Personal Information is necessary to facilitate medical treatment then such Personal Information may be collected from friends or family members;</p><p>3)	whom to contact if the Individual has any questions about the collection and use of Personal Information.</p>
                <p>2)	Personal Information may be collected from other health care providers where it is necessary to facilitate ongoing medical treatment;</p>
                <p>3)	Personal Information may be collected indirectly for the purpose of enforcement of law. For example, to comply with the judicial proceeding, court order, legal process.</p>


                <h4 className="small-head">PATIENT’S INFORMATION TO THE EMPANELLED DOCTORS/ PHYSICIANS</h4>
                <p>Patients Personal and Sensitive Information are treated with utmost security. No Personal & Sensitive Information of Patient is shared with any third party, except otherwise notified. The Doctors/Physicians empanelled with Medyseva understands the same and takes the same security measures as adopted by Medyseva to protect the privacy of the Patients. Medyseva secures the Personal & Sensitive Information of Patients are stored in secured server which is fully encrypted.</p>


                <h4 className="small-head">HOW MEDYSEVA & ITS EMPLOYEES USE INDIVIDUAL’S PERSONAL INFORMATION?</h4>
                <p>Medyseva & its Employees are committed to maintaining the confidentiality of the Individual’s Personal Information in accordance with legal and ethical standards. Medyseva and its Employees makes every effort to protect the privacy of the Individual. </p>
                <p>Their duties include but not limited to:</p>


                <p>1) adhering to all established confidentiality and Privacy Policies, procedures and laws;</p>

                <p>2)	always respecting the privacy of the Individual, their fellow Employees, medical & house staff etc.;</p>

                <p>3)	protecting and safeguarding Patient’s Health & Confidential Information and Individual’s Personal Information in all forms including paper, electronic, verbal, telephonic, or any other form;</p>
                <p>4)	accessing a Patient’s medical information only when involved in that Patient’s care, or when access is needed for a legitimate work-related reason such as medical treatment, billing, administrative, teaching or research requirements;</p>
                <p>5)	not discussing the Personal Information of the Individual in any public area including elevators, hallways, canteen or any other public area;</p>
                <p>6)	not discussing the Personal Information of the Individual in front of other patients; and</p>
                <p>7)	using Individual’s Personal Information for its intended purpose only.</p>
                <p>Medyseva & its Employees collect Personal Information directly from the Individual to whom it relates. Please note that we do not share Personal Information of Individual with any third party except those third parties as mentioned in this Policy. Even before sharing Individual’s Personal Information with any third party, prior consent is obtained from the respective Individual. All Personal Information of the Individual remains protected with us. However, we may need to disclose Individual’s Personal Information when we believe such disclosure is required in good faith and necessary to comply with judicial proceeding, court order, legal process or when needed to investigate, act against any illegal, suspected or fraudulent activity.</p>
                <p>Please note that breach of confidentiality of Personal Information by any of the Employees of Medyseva will not be tolerated.</p>



                <h4 className="small-head">Data Protection Compliances</h4>

                <p>1)	High Level Security Policy</p>
                <p>Medyseva recognizes and places strong emphasis on Individual’s service & satisfaction, by ensuring that all operating processes & procedures, Personal Information & data management systems conform to the legal requirements. Medyseva & its Employees have the responsibility to protect against unauthorized access and disclosure of Personal Information of the Individual. This responsibility includes ensuring that access or disclosure is only made to or by authorized Individual and reasonable measures are taken to prevent any unauthorized access, disclosure, loss or theft of Personal Information of the Individual.</p>


                <p>Medyseva has adopted Reasonable Security Practices and Procedures, in accordance with , to protect Individual’s Personal Information from accidental loss, theft, destruction, damage, unauthorised or unlawful processing. In addition to the Medyseva’s obligations in this respect, it is incumbent upon Employees and any other person to whom this Policy may apply, to comply with and take the following steps:</p>

                <p>a)	Adhere to Medyseva’s IT, Finance, HR and Document Retention Policies, as well as any specific guidelines issued by Medyseva from time to time;</p>

                <p>b)	Install appropriate measures to prevent data being accessed or used by any unauthorised person;</p>

                <p>c)	Not disclose Individual’s Personal Information to, or make alterations to Personal Information at the request of, a third party (including law enforcement or regulatory bodies) unless the Individual is sure that the third party has been authorised by Medyseva to receive such information or make such requests; </p>

                <p>d)	Contact the grievance officer immediately if any Individual suspects that the security of Individual’s Personal Information has been compromised.

                </p>
                <p>e)	Infrastructure & Digital Policy</p>


                <p>2)	Medyseva has implemented all reasonable safeguards including encryption of data, installation of firewalls etc. on its digital servers and portals where the Personal Information of its users is stored.</p>



                <p>3)	The infrastructure of Medyseva clinics are technologically advanced. Each clinic of Medyseva has advanced CCTV cameras, two factor authentications to cross check the identity of the Individual, many tier security check up points, video conferencing technology, 24x7 security etc.</p>

                <p>4)	Employees obligations
                  The confidentiality, integrity and availability of Individual’s Personal Information is of the utmost importance. Employees of Medyseva are under the obligation to preserve and secure the Personal Information of the Individual. At the time of joining the organization, Employees are explained about the confidentiality clause in their employment agreement. Employees are given training of how to maintain and preserve the Individual’s Personal Information. Employees are also trained to support and operate the data management systems, its procedures and manage risks.
                </p>

                <p>5)	The Employees of Medyseva are aware that any breach of confidentiality of Personal Information by any of the Employees of Medyseva will not be tolerated and the same would result into termination of the said Employee.</p>
                <p>6)	Third Party Contract
                  Medyseva may authorise a third party to access and process Individual’s Personal Information subject to putting in a procedure that is compliant with Data Protection Laws. Where Medyseva hires a third party, such third parties may include:
                </p>

                <p>a)	other members of the Medyseva group, including their Employees;</p>

                <p>b)	doctors/ physicians empanelled with Medyseva;</p>
                <p>c)	service providers, business associates, professional advisors, auditors and suppliers;</p>
                <p>d)	local or foreign regulators, governments, law enforcement authorities (including tax, social or labour authorities), courts, tribunals, arbitrators or other judicial committees; </p>
                <p>e)	finance department.
                  Please note that a prior consent will be taken from the Individual before sharing their Personal Information with the third party.
                </p>


                <h4 className="small-head">RIGHTS OF INDIVIDUAL</h4>

                <p>Individual has certain legal rights to obtain information about whether we hold Personal Information about them, to access Personal Information we hold about them, and to obtain its correction, update, amendment or deletion in appropriate circumstances. Some of these rights may be subject to some exceptions or limitations. Principal rights of the Individual under data protection laws are as follows:</p>

                <p>1)	Right to access, rectify or delete the Personal information:
                  Individual has the right to request from Medyseva to access, rectify or delete their Personal Information stored with Medyseva. Individual can ask us to verify whether we are processing Personal Information about them. Individual can ask us to rectify our records if they believe that it contain incorrect or incomplete information about them. Lastly, Individual can ask us to delete their Personal Information if they withdraw their consent or when Medyseva no longer need it for the purpose it was originally collected for.
                </p>
                <p>2)	Right to erasure
                  Individual has the right to erase/ delete their Personal Information without undue delay in circumstances where:
                </p>
                <p>a)	Personal Information is no longer necessary in relation to the purpose for which it was collected or processed;</p>
                <p>b)	they withdraw consent on which processing is based;</p>
                <p>c)	they object to the processing under certain data protection rules; </p>

                d)	Personal Information has to be erased for compliance with a legal obligation.
                Please note that there are certain exclusions of the right to erasure. Such exclusions include where processing is necessary:
                <p>a)	for exercising the right of freedom of expression and information;</p>
                <p>b)	for compliance with a legal obligation; </p>
                <p>c)	for the establishment, exercise or defence of legal claims.</p>

                <p><b>How to delete Individual’s Personal Information:
                  Individual can delete their Personal Information by sending their request at admin@medyseva.com
                </b></p>
                <br></br>
                <p>3)	Right to restrict and object to processing</p>
                <p><b>Restrict Processing:</b></p>
                <p>Individual has the right to restrict processing of their Personal Information in circumstances where they:</p>

                <p>a)	contest the accuracy of their Personal Information;</p>

                <p>b)	prefer to restrict its use rather than having us erase it;</p>
                <p>c)	believe we no longer need the Personal Information for the purpose of processing, but they require Personal Information for establishment, exercise or defence of legal claims;</p>
                <p>d)	have objected to processing, pending the verification of that objection.
                  Please note that Medyseva will duly inform them before lifting the restriction of processing.
                </p>
                <p><b>Objection to Processing:</b></p>

                <p>Individual has the right to object to processing of their Personal Information on grounds pertaining to their particular situation, but only to the extent that the processing is necessary for:</p>

                <p>1.	the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; </p>
                <p>2.	the purposes of the legitimate interests pursued by us or by a third party.
                  If an Individual makes such an objection, we will cease to process the Personal Information unless we can demonstrate compelling legitimate grounds for the processing which override their interests, rights and freedoms, or if the processing is for the establishment, exercise or defence of legal claims.
                </p>

                <p>How to restrict and object to processing:</p>

                <p>Individual can restrict and object to processing of their Personal Information by sending their queries at admin@medyseva.com</p>

                <p>4)	Right to withdraw consent
                  Individual can withdraw their consent from sharing their Personal Information with Medyseva anytime. Withdrawal of consent will not affect the lawfulness of any processing carried out before Individual withdraw their consent.
                  Individual can withdraw their consent by sending their request at admin@medyseva.com
                  Medyseva will retain Individual’s Personal Information for the establishment, exercise or defence of legal claim. Even after Individual withdraw their consent, Medyseva will continue to protect such Personal Information in accordance with this Policy.
                </p>

                <p>5)	Right to complaint to Grievance Officer
                  If any Individual is of the opinion that there is any infringement of their Personal Information at our end, then such Individual have a legal right to lodge a complaint with the Grievance Officer responsible for protection of Personal Information. The Grievance Officer responsible for protection of Individual’s Personal Information is:
                  Medyseva Technologies Private Limited
                </p>

                <p><b>Address: 18/A, Electronic Complex, Pardesipura, Indore, MP- 452010</b></p>
                <p><b>Contact no.: +91 7566975666</b></p>
                <p><b>Email: admin@medyseva.com</b></p>


                <h4 className="small-head">	RETAINING AND DELETING INDIVIDUAL’S PERSONAL INFORMATION</h4>
                <p>
                  Individual’s Personal Information will be retained in accordance with all legal, regulatory and accredited requirements as well as with any Medyseva’s record retention policies. Employees holding records containing Personal Information are expected to identify retention times and then follow the Medyseva guidelines and procedures for the secure destruction of Personal Information that is no longer required to ensure the Personal Information is destroyed or erased from the Medyseva’s database.
                </p>

                <h4 className="small-head">PRIVACY CONCERNS</h4>

                <p>If Individual has any questions or comments about the privacy of their Personal Information or about this Policy or how we handle Individual’s Personal Information, Individual could direct their correspondence to:</p>

                <p><b>Medyseva Technologies Private Limited</b></p>
                <p>they can email us at admin@medyseva.com</p>


                <p>Please note that, we respond within 30 days from the date we receive privacy related communications.</p>



                <h4 className="small-head">CONFIDENTIALITY</h4>

                <p>Individual must maintain confidentiality, and not disclose to any other person, any information and content that they access or learn in connection with their access to our Services. Individual is prohibited from sharing with any third party, photographic or other imagery, information, or any other content from any and all activities within or from the Site. Individual must not use any such information or content for any purpose other than their participation in the Services in accordance with this Agreement. Individual hereby agree to notify Medyseva immediately if they learn of or suspect any use or disclosure of, or access to, any such information or content other than as specifically authorized in this agreement.</p>

                <p>By agreeing to the above-mentioned, the Individual accept this Policy as mentioned on this website. Medyseva reserves the right to make changes to this Policy. We encourage the Individual to periodically review this Policy. Individual’s continued participation in Medyseva after any revisions to this Policy constitutes their acceptance of this Policy, as amended, modified or updated.</p>
                <p><b>Medyseva Technologies Private Limited</b></p>
                <p>Headquarters</p>
                <p><b>18/A, Electronic Complex, Pardesipura, Indore, MP- 452010</b></p>
                <br></br>
                <p><b>Patient Portal</b></p>

                <p>Call : 7566975666</p>

                <p>Write To Us At:</p>
                <p>Feedback and general queries :  info@medyseva.com</p>

                <p>PR queries :  rachita@medyseva.com, vishesh@medyseva.com</p>

                <p>Tech Support :  mehul@medyseva.com</p>
                <p>Strategic Alliances :  vishesh@medyseva.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default Privacy;
