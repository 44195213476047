import React from 'react'
import PageLayout from "../Layout/PageLayout";

const Shipping = () => {
    return (
        <PageLayout>
            <div className="col-md-12">
                <div className="head">
                    <h2>Shipping Policy</h2>
                </div>
            </div>
            <section className="privacy-second">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="terms-text">
                                <p>Thank you for choosing Medyseva for your teleconsultation needs. Please review our shipping policy below</p>
                                <br/>
                                <ol>
                                    <li><b>Digital Delivery:</b> Medyseva is a teleconsultation application that provides services digitally. There are no physical products to be shipped, as all consultations and interactions occur through the application. </li>
                                    <li><b>Instant Access:</b> Upon completing the registration process and payment, users will gain instant access to the Medyseva application and its features. Consultations with healthcare professionals can be scheduled and conducted immediately, based on availability.</li>
                                    <li><b>No Shipping Charges:</b> As there are no physical products involved, there are no shipping charges associated with the use of Medyseva. </li>
                                    <li><b>Service Availability:</b> Medyseva services are available throughout India , wherever internet access is available. Users can access the application from any location, at any time, making healthcare consultations convenient and accessible. </li>
                                    <li><b>Technical Support:</b> In case of any technical issues or questions regarding the application, our customer support team is available to assist. You can reach out to us through the support section or Contact us section within the application. </li>
                                    
                                </ol>
                                <br/>
                                <br/>
                                <p>If you have any further questions or concerns regarding our shipping policy, please don't hesitate to contact us. </p>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </PageLayout>
    )
}

export default Shipping