import React from 'react'
import PageLayout from "../Layout/PageLayout";
const Thankyou = () => {
  return (

    <PageLayout>
    <div>
        <div className="jumbotron text-center thank_t" >
  <h1 className="display-3">Thank You!</h1>
  <p className="lead">
    <strong>Thank you for getting in touch!</strong> We will be in touch with you shortly.
  </p>
  <hr />
  {/* <p>
    Having trouble? <a href="">Contact us</a>
  </p> */}
  <p className="lead">
    <a
      className="btn btn-primary btn-sm thank_b"
      href="/"
      role="button"
    >
      Continue to homepage
    </a>
  </p>
</div>
    </div>
    </PageLayout>
  )
}

export default Thankyou