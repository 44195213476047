import React from 'react'
import PageLayout from "../Layout/PageLayout";

const Terms = () => {
    return (
        <PageLayout>
            <div className="col-md-12">
                <div className="head">
                    <h2>Terms & Conditions</h2>
                </div>
            </div>
            <div>Terms</div>
        </PageLayout>
    )
}

export default Terms