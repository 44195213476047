import React from 'react'
import PageLayout from "../Layout/PageLayout";
const News = () => {
  return (

    <PageLayout>
    <div>
        <section className='news-sec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='news-list'>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://twitter.com/ExpHealthcare/status/1570756852526485508?t=vH1KLua9GSPNa-LQPHe6cQ&s=08'>
                                        <img src='assets/img/icons/express.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://twitter.com/QuantumBrainIns/status/1569687944117493766?t=QCTZ_xBDHz8uCg3_GxD0AQ&s=08'>
                                        <img src='assets/img/icons/quantum.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://twitter.com/MedTechNow_/status/1570861830008111105?t=u2O4-RfJHDSqYMHfKIw2Uw&s=08'>
                                        <img src='assets/img/icons/medtech.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://viestories.com/rural-health-tech-startup-medyseva-raises-inr-15-mn/'>
                                        <img src='assets/img/icons/vie.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://drive.google.com/file/d/1vIKeb5lRRgjAOCXCPU04bTNpvDNjzSwr/view'>
                                        <img src='assets/img/icons/patrika.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://drive.google.com/file/d/1rpanpQludpgUujyN2sBV8uRYiz0T9pZ3/view'>
                                        <img src='assets/img/icons/dabang.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank"  href='https://drive.google.com/file/d/1UIyrzoftFZgPmbiCzr3kgUSoHyhu-pyb/view'>
                                        <img src='assets/img/icons/chronicle.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://drive.google.com/file/d/1PHQIWFYdBQ9KrGxRqIGoJuzASZlaEEgg/view'>
                                        <img src='assets/img/icons/rajexpress.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://www.dailypioneer.com/2022/business/angel-funding-for-telemedicine.html'>
                                        <img src='assets/img/icons/pioneer.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='http://www.univarta.com/story/Business/news/2822070.html'>
                                        <img src='assets/img/icons/uni.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://yourstory.com/companies/medyseva'>
                                        <img src='assets/img/icons/your-story.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://m.facebook.com/story.php?story_fbid=pfbid02CmuQgmwYY7MPnnYu1gekRrs56Ecfqzg3A6RDstbaxWWA46WWuef2q1Anqww8eW4cl&id=100063752746033&sfnsn=wiwspwa'>
                                        <img src='assets/img/icons/csr.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://drive.google.com/file/d/1fVXt2x40uQu8oo2zOvDLr9hLF-RCKyNQ/view'>
                                        <img src='assets/img/icons/dainik_bhaskar.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://mybigplunge.com/life/health/medyseva-raises-inr-15-million-to-rollout-nationwide-patient-care/'>
                                        <img src='assets/img/icons/plunge.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://drive.google.com/file/d/1riCjkenCHxkPGlb60yFImViteF2GtZnZ/view'>
                                        <img src='assets/img/icons/jagran.png'/>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className='text-center'>
                                    <a target="_blank" href='https://startupstorymedia.com/insights-rural-healthtech-startup-medyseva-raises-rs-15-cr-in-pre-series-a-round/'>
                                        <img src='assets/img/icons/startup.png'/>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
    </PageLayout>
  )
}

export default News