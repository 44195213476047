import React, { useState, useEffect } from "react";
import PageLayout from './../Layout/PageLayout';
import { Modal, Button, Form } from "react-bootstrap";

const Medytalk = () => {
  const [showModal, setShowModal] = useState({
    showblock:false,
    showlink:''
  });

  const handleModalClose = () => {
    setShowModal({
      showblock:false,
      showlink:'',
    });
  };
  return (
    <div>
      <PageLayout>
      <div className="col-md-12">
        <div className="head text-center medytalk-heading-page">
         <img alt='' src="assets/img/medytalk-heading-logo.png" />
        </div>
      </div>
        <div className="medytalk-section">
          <div className="container-fluid">
            <div className="medytalk-inner">
              <div className="row justify-content-between">
              <div className="col-md-4 col-lg-2 col-sm-6">
                  <div className="medytalk-item">
                    <div className="medytalk-img">
                      <img alt='' className="medyprofile" src="assets/img/medytalk-1.png" />
                      <div className="medytalk-logo">
                        <img alt='' src="assets/img/medytalk-logo.png" />
                        <div className="play-btn">
                        <div className="video-head">
                          <div className="video-contain1">
                          <a  onClick={() => {
                            setShowModal({
                              showblock:true,
                              showlink:'https://www.youtube.com/embed/ncJC-nCJKYY?autoplay=1'
                            });
                          }}
                          className="popup-link1 video"><i className="fa fa-play"></i></a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="medytalk-info">
                      <div className="logo">
                        <img alt='' src="assets/img/medytalk-info-logo.png" />
                        <img alt='' src="assets/img/medyseva.png" />
                      </div>
                      <ul>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Topic:</h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Abnormal Uterine Bleeding</p>
                          </div>
                        </li>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Dr. Name: </h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Dr. Richa Patel.</p>
                          </div>
                        </li>
                      </ul>
                      <div className="text-right date-sec">
                        <h6>Date: </h6> <p> 05-Dec-2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-6 ">
                  <div className="medytalk-item">
                    <div className="medytalk-img">
                      <img alt='' className="medyprofile" src="assets/img/medytalk-img.png" />
                      <div className="medytalk-logo">
                        <img alt='' src="assets/img/medytalk-logo.png" />
                        <div className="play-btn">
                        <div className="video-head">
                          <div className="video-contain1">
                          <a  onClick={() => {
                            setShowModal({
                              showblock:true,
                              showlink:'https://www.youtube.com/embed/wZaogpwJeiY?autoplay=1'
                            });
                          }}
                          className="popup-link1 video"><i className="fa fa-play"></i></a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="medytalk-info">
                      <div className="logo">
                        <img alt='' src="assets/img/medytalk-info-logo.png" />
                        <img alt='' src="assets/img/medyseva.png" />
                      </div>
                      <ul>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Topic:</h6>
                          </div>
                          <div className="medytalk-heading-info">
                          <p>World Constipation Month.</p> 
                          </div>
                        </li>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Dr. Name: </h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Dr. Nitieka Rawal.</p>
                          </div>
                        </li>
                      </ul>
                      <div className="text-right date-sec">
                        <h6>Date: </h6><p> 05-Dec-2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-6 ">
                  <div className="medytalk-item">
                    <div className="medytalk-img">
                      <img alt='' className="medyprofile" src="assets/img/Artboard 1@4x.png" />
                      <div className="medytalk-logo">
                        <img alt='' src="assets/img/medytalk-logo.png" />
                        <div className="play-btn">
                        <div className="video-head">
                          <div className="video-contain">
                          <a onClick={() => {
                            setShowModal({
                              showblock:true,
                              showlink:'https://www.youtube.com/embed/Q49S-JAnwXQ?autoplay=1'
                            });
                          }} className="popup-link video"><i className="fa fa-play"></i></a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="medytalk-info">
                      <div className="logo">
                        <img alt='' src="assets/img/medytalk-info-logo.png" />
                        <img alt='' src="assets/img/medyseva.png" />
                      </div>
                      <ul>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Topic:</h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Special discussion on AIDS</p>
                          </div>
                        </li>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Dr. Name: </h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Dr. Abhishek Agrawal.</p>
                          </div>
                        </li>
                      </ul>
                      <div className="text-right date-sec">
                        <h6>Date: </h6><p> 05-Dec-2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-6 ">
                  <div className="medytalk-item">
                    <div className="medytalk-img">
                      <img alt='' className="medyprofile" src="assets/img/Artboard 4@4x.png" />
                      <div className="medytalk-logo">
                        <img alt='' src="assets/img/medytalk-logo.png" />
                        <div className="play-btn">
                        <div className="video-head">
                          <div className="video-contain">
                          <a 
                          onClick={() => {
                            setShowModal({
                              showblock:true,
                              showlink:'https://www.youtube.com/embed/_jzfcS2gN5c?autoplay=1'
                            });
                          }}
                          className="popup-link video"><i className="fa fa-play"></i></a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="medytalk-info">
                      <div className="logo">
                        <img alt='' src="assets/img/medytalk-info-logo.png" />
                        <img alt='' src="assets/img/medyseva.png" />
                      </div>
                      <ul>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Topic:</h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Diabetes in children</p>
                          </div>
                        </li>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Dr. Name: </h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Dr. Sharwari Dua.</p>
                          </div>
                        </li>
                      </ul>
                      <div className="text-right date-sec">
                        <h6>Date: </h6><p> 05-Dec-2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-6 ">
                  <div className="medytalk-item">
                    <div className="medytalk-img">
                      <img alt='' className="medyprofile" src="assets/img/Artboard 2@4x.png" />
                      <div className="medytalk-logo">
                        <img alt='' src="assets/img/medytalk-logo.png" />
                        <div className="play-btn">
                        <div className="video-head">
                          <div className="video-contain">
                          <a 
                          onClick={() => {
                            setShowModal({
                              showblock:true,
                              showlink:'https://www.youtube.com/embed/r83mnIEptzc?autoplay=1'
                            });
                          }}
                          
                          className="popup-link video"><i className="fa fa-play"></i></a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="medytalk-info">
                      <div className="logo">
                        <img alt='' src="assets/img/medytalk-info-logo.png" />
                        <img alt='' src="assets/img/medyseva.png" />
                      </div>
                      <ul>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Topic:</h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Oral Hygiene in Childrens</p>
                          </div>
                        </li>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Dr. Name: </h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Dr. Shefaly Tandon.</p>
                          </div>
                        </li>
                      </ul>
                      <div className="text-right date-sec">
                        <h6>Date: </h6><p> 05-Dec-2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-6 ">
                  <div className="medytalk-item">
                    <div className="medytalk-img">
                      <img alt='' className="medyprofile" src="assets/img/Artboard 3@4x.png" />
                      <div className="medytalk-logo">
                        <img alt='' src="assets/img/medytalk-logo.png" />
                        <div className="play-btn">
                        <div className="video-head">
                          <div className="video-contain">
                          <a  
                            onClick={() => {
                              setShowModal({
                                showblock:true,
                                showlink:'https://www.youtube.com/embed/eXbsi15yRwM?autoplay=1'
                              });
                            }}
                            
                          className="popup-link video"><i className="fa fa-play"></i></a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="medytalk-info">
                      <div className="logo">
                        <img alt='' src="assets/img/medytalk-info-logo.png" />
                        <img alt='' src="assets/img/medyseva.png" />
                      </div>
                      <ul>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Topic:</h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p>Menstruation for girls</p>
                          </div>
                        </li>
                        <li>
                          <div className="medytalk-heading">
                            <h6>Dr. Name: </h6>
                          </div>
                          <div className="medytalk-heading-info">
                            <p> Dr. Mradulika Sharma.</p>
                          </div>
                        </li>
                      </ul>
                      <div className="text-right date-sec">
                        <h6>Date: </h6><p> 05-Dec-2022</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
      <Modal
          show={showModal.showblock}
          onHide={handleModalClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton={handleModalClose}> </Modal.Header>
          <Modal.Body>
          <iframe allowfullscreen="true" webkitallowfullscreen="true" width="100%" height="350" src={showModal.showlink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
          </Modal.Body>
        </Modal>
     
    </div>
  )
}

export default Medytalk