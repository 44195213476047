import './App.css';
import { Route,Routes} from "react-router-dom";
import HomePage from './Component/HomePage/HomePage';

import Thankyou from './Component/Pages/Thankyou';
import News from './Component/Pages/News';
import Terms from './Component/Pages/Terms';
import Privacy from './Component/Pages/Privacy';
import Medytalk from './Component/Pages/Medytalk';
import Shipping from './Component/Pages/Shipping';
import MedysevaTerms from './Component/Pages/MedysevaTerms';
import ReturnsAndRefund from './Component/Pages/ReturnsAndRefund';
import Medymate from './Component/Pages/Medymate';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/news" element={<News />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/medytalk" element={<Medytalk />} />
        <Route path="/shipping" element={<Shipping/>} />
        <Route path="/tc" element={<MedysevaTerms/>} />
        <Route path="/refund" element={<ReturnsAndRefund/>} />
        <Route path="/medymate" element={<Medymate/>} />
        
      </Routes>
    </>
  );
}

export default App;
