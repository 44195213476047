import React, { useState } from "react";
import Footer from "../Footer";
import PageLayout from "../Layout/PageLayout";
import ContactUs from "../Pages/ContactUs";
import * as Yup from "yup";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axios } from "../../http";
import Slider from "react-slick";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import InstagramEmbed from 'react-instagram-embed';
//import { IGEmbed } from "react-ig-embed";
import { LinkedInEmbed } from "react-social-media-embed";   

const Medymate = () => {
    const startone = () => {
        const start1 = document.getElementById("vid1");
        const start2 = document.getElementById("vid2");
        const start3 = document.getElementById("vid3");
        const start4 = document.getElementById("vid4");
        start1.controls = true;
        start2.controls = true;
        start3.controls = true;
        start4.controls = true;
      };
      const closevideo = () => {
        const new11 = document.getElementById("vid1");
        const new12 = document.getElementById("vid2");
        const new13 = document.getElementById("vid3");
        const new14 = document.getElementById("vid4");
        new11.pause();
        new12.pause();
        new13.pause();
        new14.pause();
      };
    
      const [joinTab, setJoinTab] = useState(1);
    
      const navigateToContact = () =>{
        window.location='/#contact-info'
      }

      const HandleDoctorSubmit = async (values) => {
        const res = await axios
          .post(
            `api/contact_us?contactus_type=${"doctor"}&doctor_name=${
              values.doctor_name
            }&doctor_email=${values.doctor_email}&doctor_number=${
              values.doctor_number
            }&doctor_specialization=${values.doctor_specialization}&center_name=${
              values.center_name
            }&center_phone_num=${values.center_phone_num}&center_address=${
              values.center_address
            }&center_email=${values.center_email}`
          )
          .then((res) => {
            console.log(res.data.msg);
            toast(res.data.msg);
          })
          .catch((err) => {
            toast(err.message);
          });
      };
      const HandleCenterSubmit = async (values) => {
        const res = await axios
          .post(
            `api/contact_us?contactus_type=${"center"}&doctor_name=${
              values.doctor_name
            }&doctor_email=${values.doctor_email}&doctor_number=${
              values.doctor_number
            }&doctor_specialization=${values.doctor_specialization}&center_name=${
              values.center_name
            }&center_phone_num=${values.center_phone_num}&center_address=${
              values.center_address
            }&center_email=${values.center_email}`
          )
          .then((res) => {
            console.log(res.data.msg);
            toast(res.data.msg);
          })
          .catch((err) => {
            toast(err.message);
          });
      };
    
      const validationSchema = Yup.object({
        doctor_name: Yup.string().trim().required("Doctor name is required"),
        doctor_email: Yup.string()
          .email()
          .typeError()
          .required("E-mail id required"),
        doctor_number: Yup.string()
          .trim()
          .min(10)
          .max(10)
          .required("Phone number is required"),
        doctor_specialization: Yup.string().required("Specialization is required"),
      });
      const validationSchema1 = Yup.object({
        center_name: Yup.string().trim().required("Name is required"),
        center_email: Yup.string()
          .email()
          .typeError()
          .required("E-mail id required"),
        center_number: Yup.string()
          .trim()
          .min(10)
          .max(10)
          .required("Phone number is required"),
        center_address: Yup.string().required("Address is required"),
      });
    
      //
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings8 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings3 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
      const settings4 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings5 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings6 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      return (
        <>
          <div>
            <PageLayout />
            <div>
                <section id="banner-slider">
                    <div className="top-banner-slider">
                        <Slider {...settings}>
                            <div className="slide medymate-slide1">                    
                                <div className="container">
                                    <div className="header-content">
                                        <div className="head">
                                            <h2 style={{marginTop:"135px"}}>
                                            <b style={{fontSize:"50px"}}>Welcome to Medymate</b> <br />
                                            <b style={{fontSize:"40px"}}>We prioritize your health while</b> <br />
                                            <b style={{fontSize:"40px"}}>you pursue your education</b> <br />                              
                                            </h2>                            
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </Slider>
                    </div>
                </section>          
                <section id="video">
                    <div className="container mt-2 mb-2" id="about">
                        <div className="row">
                            
    
                            <div className="col-md-12 text-center">                                                                
                                <h2 className="mt-2 mb-4"><b>Introduction</b></h2>                                
                                <div className="">
                                    <p><b>
                                    Welcome to MedyMate, where we prioritize the health and well-being of urban area students pursuing their academic goals. Our mission is to revolutionize healthcare for students by offering comprehensive and affordable solutions tailored to their unique needs.
                                    </b></p>                                                                        
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <section id="video">
                    <div className="container" id="about">
                        <div className="row medymate-healthcare-bg">                                                    
                            <div className="col-md-6">
                                <div className="">
                                    <img src="assets/img/medymate/health-companion-2.png" width="400"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-12">
                                    <div className="">
                                        <h2><b>Your <span style={{color:"#5AC5E5"}}>Healthcare</span> Companion</b></h2>
                                    </div>
                                </div>
                                <div className="des">
                                    <p><b>
                                    MedyMate is your dedicated healthcare companion, committed to providing structured and reliable healthcare services to educational premises. Whether you're a college, school, or hostel, we collaborate with you to ensure that your students and staff have access to top-notch healthcare solutions
                                    </b></p>                                                                        
                                </div>
                                <button type="button" className="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#read-more"> Read More</button>
                            </div>
                        </div>
                    </div>
                </section>
              
    
              <section id="services" className="bg-white mt-2">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-12 text-center mb-4">
                    
                        <h2><b>Our Four <span style={{color:"#5AC5E5"}}>Verticles</span></b></h2>
                    
                    </div>
                    <div className="col-md-12 mt-4 mb-4">
                      <div className="services-slider-medymate">
                        {/* <div className="owl-carousel"> */}
                        <Slider {...settings1}>
                            <div className="slide">
                                <div className="h-100">
                                    <div className="service-card ">
                                        <div className="card medymate-card ">
                                            <div className="medymate-card-shape"></div>
                                            <img
                                            src="assets/img/medymate/medymind-logo.png"
                                            className=""
                                            alt="..."
                                            />
                                            <div className="card-body bg-white ">
                                            
                                            <p className="card-text text-center" style={{color:"#000 !important"}}>
                                                <b>
                                                Holistic mental health support through counseling, workshops, meditation resources, and more.
                                            </b>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slide">
                                <div className="h-100">
                                    <div className="service-card ">
                                        <div className="card medymate-card">
                                            <div className="medymate-card-shape"></div>
                                            <img
                                            src="assets/img/medymate/medyvision-logo.png"
                                            className=""
                                            alt="..."
                                            />
                                            <div className="card-body bg-white ">
                                            
                                            <p className="card-text text-center" style={{color:"#000 !important"}}>
                                                <b>
                                                Advanced eye care solutions including screening, educational workshops, and eye safety programs.
                                            </b>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slide">
                                <div className="h-100">
                                    <div className="service-card ">
                                        <div className="card medymate-card ">
                                            <div className="medymate-card-shape"></div>
                                            <img
                                            src="assets/img/medymate/medyshe-logo.png"
                                            className=""
                                            alt="..."
                                            />
                                            <div className="card-body bg-white ">
                                            
                                            <p className="card-text text-center" style={{color:"#000 !important"}}>
                                                <b>
                                                Adapt health solutions for women's well-being, from clinics to fitness programs.
                                            </b>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slide">
                                <div className="h-100">
                                    <div className="service-card ">
                                        <div className="card  medymate-card">
                                            <div className="medymate-card-shape"></div>
                                            <img
                                            src="assets/img/medymate/medydite-logo.png"
                                            className=""
                                            alt="..."
                                            />
                                            <div className="card-body bg-white ">
                                            
                                            <p className="card-text text-center" style={{color:"#000 !important"}}>
                                                <b>
                                                Personalized nutrition guidance for healthier living, including education programs and counseling.
                                            </b>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                                                                                    
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    
              
                
                <section id="counter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                            <h2><b><span style={{color:"#5AC5E5"}}>Medymate</span> Offerings</b></h2>
                            </div>
                            <div className="col-lg-4">
                                <div className="card shadow medymate-card-two ">
                                    <div className="medymate-card-shape-two"></div>
                                    <img
                                    src="assets/img/medymate/medymate-offerings-1.png"
                                    className="medymate-card-img"
                                    alt="..."
                                    />
                                    <div className="card-body bg-white ">

                                        <p className="card-text text-center" style={{color:"#000 !important"}}>
                                            <b>
                                            Mobile App for easy access to our services.
                                        </b>
                                        </p>
                                    </div>
                                </div>
                            </div>                            
                            <div className="col-lg-4">
                                <div className="card shadow medymate-card-two ">
                                    <div className="medymate-card-shape-two"></div>
                                    <img
                                    src="assets/img/medymate/medymate-offerings-2.png"
                                    className="medymate-card-img"
                                    alt="..."                                    
                                    />
                                    <div className="card-body bg-white ">

                                        <p className="card-text text-center" style={{color:"#000 !important"}}>
                                            <b>
                                            Unlimited tele-consultations for immediate assistance.
                                        </b>
                                        </p>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-4">
                                <div className="card shadow medymate-card-two ">
                                    <div className="medymate-card-shape-two"></div>
                                    <img
                                    src="assets/img/medymate/medymate-offerings-3.png"
                                    className="medymate-card-img"
                                    alt="..."
                                    />
                                    <div className="card-body bg-white ">

                                        <p className="card-text text-center" style={{color:"#000 !important"}}>
                                            <b>
                                            Doorstep Pathology and Diagnostics for convenience
                                        </b>
                                        </p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="row">                           
                            <div className="col-lg-4">
                                <div className="card shadow medymate-card-two ">
                                    <div className="medymate-card-shape-two"></div>
                                    <img
                                    src="assets/img/medymate/medymate-offerings-4.png"
                                    className="medymate-card-img"
                                    alt="..."
                                    />
                                    <div className="card-body bg-white ">

                                        <p className="card-text text-center" style={{color:"#000 !important"}}>
                                            <b>
                                            Hospital Assistance for seamless healthcare access.
                                        </b>
                                        </p>
                                    </div>
                                </div>
                            </div>                            
                            <div className="col-lg-4">
                                <div className="card shadow medymate-card-two ">
                                    <div className="medymate-card-shape-two"></div>
                                    <img
                                    src="assets/img/medymate/medymate-offerings-5.png"
                                    className="medymate-card-img"
                                    alt="..."                                    
                                    />
                                    <div className="card-body bg-white ">

                                        <p className="card-text text-center" style={{color:"#000 !important"}}>
                                            <b>
                                            Nursing Care and Ambulance Services for emergencies
                                        </b>
                                        </p>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-4">
                                <div className="card shadow medymate-card-two ">
                                    <div className="medymate-card-shape-two"></div>
                                    <img
                                    src="assets/img/medymate/medymate-offerings-6.png"
                                    className="medymate-card-img"
                                    alt="..."
                                    />
                                    <div className="card-body bg-white ">

                                        <p className="card-text text-center" style={{color:"#000 !important"}}>
                                            <b>
                                            Physical Setup to ensure a conducive healthcare environment
                                        </b>
                                        </p>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </section>
                <section id="video">
                    <div className="container-fluid" id="about">
                        <div className="row">
                          <div className="afforable-medymate-section-bg"></div>
                          <img src="assets/img/medymate/affordable-reliable-healthcare-image.png" className="afforable-medymate-section-img" alt=".."/>

                            <div className="afforable-medymate-section-text text-white">
                                <h2><b>Affordable and Reliable Healthcare Solutions</b></h2>
                                <div className="mt-4">                                
                                    <p><b>
                                    At MedyMate, we understand the importance of affordability and reliability in healthcare. That's why we offer cost-effective solutions without compromising on quality, ensuring that students and staff can access the care they need without financial strain
                                    </b></p>                                                                        
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </section>
                <section className="cta-medymate-section">
                    <div className="row">
                        <div className="col-md-12 text-center mb-4">                    
                            <h2><b>Experience Convenience Convergence with <span style={{color:"#5AC5E5"}}>MedyMate</span> App</b></h2>                    
                        </div>
                        <div className="col-md-12 text-center mb-4">                    
                            <p><b>Access our services through our user-friendly mobile app. From unlimited tele-consultations to<br/> doorstep diagnostics, MedyMate ensures easy access to all our offerings at your fingertips.</b></p>                    
                        </div>
                    </div>
                    <div className="banner-image"></div>
                    
                </section>

                <section className="cta-medymate-section-2">
                    <div className="row">
                        <div className="col-md-12 text-center mb-2">                    
                            <h2><b>Seeking Comprehensive <span style={{color:"#5AC5E5"}}>Healthcare</span> Solutions</b></h2>                    
                        </div>
                        <div className="col-md-12 text-center mb-4">                    
                            <p><b>If you are associated with School or college or running a hostel and are looking for comprehensive<br/> healthcare solutions, interested in partnering with us and seeking a quick call back? </b></p>                    
                            <button onClick={()=>navigateToContact()}type="button" className="btn btn-primary cta-btn" ><img src="assets/img/medymate/phone-icon.png"alt=".." width="20px"/> Contact Us Now!</button>
                        </div>
                        
                    </div>
                    
                    
                </section>

                <section className="pre-footer">
                   
                                <a href="/#about" className="pre-footer-link">About Us</a>
                                <a href="/#about" className="pre-footer-link pre-footer-link-list">Awards</a>
                                <a href="/#about" className="pre-footer-link pre-footer-link-list">Experience</a>
                                <a href="/#about" className="pre-footer-link pre-footer-link-list">Our Mission</a>
                   
                </section>
    
             
    
            </div>
            
            
            <div
              className="modal fade"
              id="read-more"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                id="aboutUs_modal"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-center" id="exampleModalLabel">
                      More about your Health Care Companion
                    </h5>
                    {/*     color: var(--primary-color); */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="content-des">                      
                      <p>Welcome to MedyMate, your trusted healthcare companion, designed to cater to the unique needs of educational institutions. At MedyMate, we understand the importance of prioritizing the health and well-being of students and staff members alike. That's why we've crafted a comprehensive suite of healthcare solutions tailored specifically for educational premises, including colleges, schools, and hostels.</p><br/>
                      <p>Our primary goal at MedyMate is to provide structured and reliable healthcare services that seamlessly integrate into the daily operations of educational institutions. We recognize that each institution has its own set of requirements and challenges when it comes to healthcare management. Therefore, we work closely with you to develop customized solutions that address your specific needs while adhering to the highest standards of quality and efficiency.</p><br/>
                      <p>With MedyMate as your healthcare partner, you can rest assured knowing that your students and staff have access to top-notch medical care and support whenever they need it. Our team of experienced healthcare professionals is dedicated to delivering prompt and personalized services, ensuring that everyone in your institution receives the attention and care they deserve.</p><br/>
                      <p>From routine medical check-ups to emergency response protocols, MedyMate offers a wide range of services aimed at promoting the health and well-being of your educational community. We believe that a healthy campus is a thriving campus, and we're committed to helping you create a safe and supportive environment where everyone can excel.</p><br/>
                      <p>At MedyMate, we're more than just a healthcare provider – we're your partner in promoting a healthier, happier, and more productive educational environment. Contact us today to learn more about how we can support your institution's healthcare needs and help you create a campus community that thrives</p><br/>
                    </div>
                  </div>
                </div>
              </div>
            </div>               
            <Footer />
          </div>
          <ToastContainer />
        </>
      );
}

export default Medymate