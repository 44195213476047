import React from 'react'
import PageLayout from "../Layout/PageLayout";

const MedysevaTerms = () => {
    return (
        <PageLayout>
            <div className="col-md-12">
                <div className="head">
                    <h2>Terms and Conditions:</h2>
                </div>
            </div>
            <section class="privacy-second">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="terms-text">
                                <p>Welcome to Medyseva! Before using our teleconsultation services, please review the following terms and conditions: </p>
                                <br/>
                                <ol>                                
                                    <li><b>Acceptance of Terms:</b> By accessing or using the Medyseva application, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, please do not use our services. </li>
                                    <li><b>User Eligibility:</b> Users must be at least 15 years of age to use Medyseva services. By using the application, you confirm that you are of legal age to form a binding contract. </li>
                                    <li><b>Teleconsultation Services:</b> Medyseva provides a platform for users to connect with healthcare professionals for teleconsultations. These consultations do not replace in-person medical care but provide convenient access to medical advice and information. </li>
                                    <li><b>Privacy:</b> We take your privacy seriously. Please review our privacy policy to understand how we collect, use, and safeguard your personal information. </li>
                                    <li><b>Payment:</b> Payment is required for accessing certain features and services within the Medyseva application. All payments are processed securely through our payment gateway. </li>
                                    <li><b>User Conduct:</b> Users are expected to conduct themselves in a respectful manner during consultations. Any misuse or abuse of the platform may result in the termination of services. </li>
                                    <li><b>Intellectual Property:</b> All content and materials provided through the Medyseva application are the property of Medyseva. Users may not reproduce, distribute, or modify any content without prior permission. </li>
                                    <li><b>Disclaimer:</b> While we strive to provide accurate and reliable information, Medyseva does not guarantee the accuracy or completeness of the information provided during consultations. Users are advised to consult with a qualified healthcare professional for medical advice. </li>
                                    <li><b>Modification of Terms:</b> Medyseva reserves the right to modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the application constitutes acceptance of the modified terms. </li>
                                    <li><b>Contact Us:</b> If you have any questions or concerns about these terms and conditions, please contact us at info@medyseva.com. </li>
                                </ol>
                                <br/>
                                <br/>
                                <p>By using Medyseva, you agree to be bound by these terms and conditions. Thank you for choosing us for your teleconsultation needs! </p>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </PageLayout>
    )
}

export default MedysevaTerms