import React from 'react'
import PageLayout from "../Layout/PageLayout";

const ReturnsAndRefund = () => {
    return (
        <PageLayout>
            <div className="col-md-12">
                <div className="head">
                    <h2>Cancellation/Refund Policy</h2>
                </div>
            </div>
            <section className="privacy-second">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="terms-text">
                                <p>At Medyseva, we understand that plans may change, and sometimes you may need to cancel your consultation. Please review our cancellation and refund policy below: </p>
                                <br/>
                                <p><b> Refund Processing:</b>While appointments are non-refundable, we understand that circumstances may arise that necessitate rescheduling. If you need to cancel your appointment, please note that refunds are not issued. However, you have the option to re-book your appointment by contacting our customer support team. We will be happy to assist you in rescheduling your consultation for a more convenient time. </p>                                
                                <br/>
                                <br/>
                                <p>If you have any further questions or concerns regarding our shipping policy, please don't hesitate to contact us. </p>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </PageLayout>
    )
}

export default ReturnsAndRefund